import { db } from "../config/firebase";
import {
  doc,
  getDoc,
  addDoc,
  updateDoc,
  arrayRemove,
  arrayUnion,
  deleteDoc,
  limit,
} from "firebase/firestore";
import { collection, query, where, getDocs, orderBy } from "firebase/firestore";
import {
  getStorage,
  ref,
  getDownloadURL,
  uploadBytes,
  uploadString,
  listAll,
} from "firebase/storage";

const storage = getStorage();

// Create inputs and them to a screen
// Get inputs list, create a document for each one and store the id in the screen
const addInputsToScreen = async (screenId, inputsToAdd) => {
  try {
    // Fonction pour créer un input dans Firestore et retourner son ID
    const createInput = async (input) => {
      const inputRef = await addDoc(collection(db, "inputs"), input);
      return inputRef.id;
    };

    // Traiter les inputs et obtenir les ID de tous les inputs
    const inputIds = [];
    for (const input of inputsToAdd) {
      const inputId = await createInput(input);
      inputIds.push(inputId);
    }

    // Remplace les inputs par leurs ID dans l'écran
    const screenRef = doc(db, "screens", screenId);
    await updateDoc(screenRef, {
      inputs: inputIds,
    });

    console.log("Inputs added to screen with ID: ", screenId);
  } catch (e) {
    console.error("Error adding inputs to screen: ", e);
  }
};

// Delete all screens
const deleteAllScreens = async () => {
  try {
    const screensCollectionRef = collection(db, "screens");
    const querySnapshot = await getDocs(screensCollectionRef);

    querySnapshot.forEach(async (doc) => {
      await deleteDoc(doc.ref);
      console.log("Screen deleted with ID: ", doc.id);
    });
  } catch (e) {
    console.error("Error deleting screens: ", e);
  }
};

// Delete all inputs
const deleteAllInputs = async () => {
  try {
    const inputsCollectionRef = collection(db, "inputs");
    const querySnapshot = await getDocs(inputsCollectionRef);

    querySnapshot.forEach(async (doc) => {
      await deleteDoc(doc.ref);
      console.log("Input deleted with ID: ", doc.id);
    });
  } catch (e) {
    console.error("Error deleting inputs: ", e);
  }
};

// Add screens to an engagement
const addScreenToEngagement = async (engagementId, screensToAdd) => {
  try {
    // Fonction pour créer un écran dans Firestore et retourner son ID
    const createScreen = async (screen) => {
      const screenRef = await addDoc(collection(db, "screens"), screen);
      return screenRef.id;
    };

    // Fonction récursive pour traiter chaque écran et ses enfants
    const processScreens = async (screens, parentId = null) => {
      let ids = [];

      // Parcours de chaque élément
      for (const screen of screens) {
        let newScreen = { ...screen, parentId, children: [] };

        // Si l'écran a des enfants, traiter les enfants
        if (screen.hasChildren && screen.children?.length > 0) {
          const childIds = await processScreens(screen.children);
          newScreen.children = childIds;
        }

        const screenId = await createScreen(newScreen);
        ids.push(screenId);

        // Si l'écran a des inputs, les ajouter à l'écran
        if (screen.inputs && screen.inputs.length > 0) {
          await addInputsToScreen(screenId, screen.inputs);
        }
      }

      return ids;
    };

    // Traiter les écrans et obtenir les ID de tous les écrans de niveau supérieur
    const topScreenIds = await processScreens(screensToAdd);

    // Mettre à jour l'engagement avec les ID des écrans de niveau supérieur
    const engagementRef = doc(db, "engagements", engagementId);
    await updateDoc(engagementRef, {
      screens: arrayUnion(...topScreenIds),
    });

    console.log("Screens added to engagement with ID: ", engagementId);
  } catch (e) {
    console.error("Error adding screens to engagement: ", e);
  }
};

// Set screenLock by screen id
const setScreenLock = async (screenId, lock) => {
  try {
    const screenRef = doc(db, "screens", screenId);
    await updateDoc(screenRef, {
      locked: lock,
    });

    console.log("Screen locked with ID: ", screenId);
  } catch (e) {
    console.error("Error setting screen lock: ", e);
  }
};

// addScreenToEngagement("Gkt5x0EnIPX5FmideeEh", screens.screens);

// Delete cascade screens by screen id
const deleteCascadeScreens = async (screenId) => {
  try {
    // Fonction récursive pour supprimer un écran et ses enfants
    const deleteScreenWithChildren = async (screenId) => {
      const screenRef = doc(db, "screens", screenId);
      const screenSnap = await getDoc(screenRef);

      if (!screenSnap.exists()) {
        return;
      }

      let screen = { id: screenSnap.id, ...screenSnap.data() };

      if (screen.children && screen.children.length > 0) {
        for (const childId of screen.children) {
          await deleteScreenWithChildren(childId);
        }
      }

      await deleteDoc(screenRef);
      console.log("Screen deleted with ID: ", screenId);
    };

    await deleteScreenWithChildren(screenId);
  } catch (e) {
    console.error("Error deleting cascade screens: ", e);
  }
};

// Create an engagement
const createEngagement = async (details) => {
  const engagement = {
    client_id: details.client.id,
    type: details.type.nom,
    type_id: details.type.id,
    created_at: new Date(),
    last_modified: new Date(),
    creator_id: details.creator_id,
    authorized_users_ids: [details.creator_id],
    organisation_id: details.organisation_id,
    screens: [],
    state: "En attente",
    description: details.description,
  };

  try {
    const docRef = await addDoc(collection(db, "engagements"), engagement);
    console.log("Document written with ID: ", docRef.id);

    let templateName = "template" + engagement.type_id;
    const { screens, appScreens } = await import(
      `../templates/${templateName}`
    );
    // Add screens to the engagement
    await addScreenToEngagement(docRef.id, screens.screens);
  } catch (e) {
    console.error("Error adding document: ", e);
  }
};

// Update an engagement
const updateEngagement = async (engagementId, details) => {
  try {
    const engagementRef = doc(db, "engagements", engagementId);
    await updateDoc(engagementRef, details);
    console.log("Document updated with ID: ", engagementId);
  } catch (e) {
    console.error("Error updating document: ", e);
  }
};

// Get all engagements
const getEngagements = async (resultsLimit, currentUser) => {
  if (!currentUser) {
    return [];
  }

  try {
    const engagementsCollectionRef = collection(db, "engagements");
    const engagementsQuery = query(
      engagementsCollectionRef,
      limit(resultsLimit),
      orderBy("created_at", "desc"),
    );
    const querySnapshot = await getDocs(engagementsQuery);

    let engagements = [];
    console.log("Current user: ", currentUser);
    querySnapshot.forEach((doc) => {
      console.log("Document data: ", doc.data());
      // Check is the current user id is in the authorized_users_ids array of the engagement
      if (
        !currentUser.uid ||
        doc.data().authorized_users_ids.includes(currentUser.uid)
      ) {
        engagements.push({ id: doc.id, ...doc.data() });
      }
    });

    // Add client name to each engagement
    for (let i = 0; i < engagements.length; i++) {
      const clientDoc = await getDoc(
        doc(db, "clients", engagements[i].client_id),
      );
      engagements[i].client = clientDoc.data();
    }

    // Add creator name to each engagement
    for (let i = 0; i < engagements.length; i++) {
      const creatorDoc = await getDoc(
        doc(db, "users", engagements[i].creator_id),
      );
      engagements[i].creator = creatorDoc.data();
    }

    // Parse firebase timestamp into react date
    for (let i = 0; i < engagements.length; i++) {
      engagements[i].created_at = engagements[i].created_at.toDate();
      engagements[i].last_modified = engagements[i].last_modified.toDate();
    }

    return engagements;
  } catch (error) {
    console.error("Error getting engagements: ", error);
    return [];
  }
};

// Fonction récursive pour récupérer un écran et ses enfants
const getScreenWithChildren = async (screenId) => {
  const screenRef = doc(db, "screens", screenId);
  const screenSnap = await getDoc(screenRef);

  if (!screenSnap.exists()) {
    return null;
  }

  let screen = { id: screenSnap.id, ...screenSnap.data() };

  screen.uid = screenSnap.id;

  if (screen.children && screen.children.length > 0) {
    let children = [];
    for (const childId of screen.children) {
      const childScreen = await getScreenWithChildren(childId);
      if (childScreen) {
        children.push(childScreen);
      }
    }
    screen.children = children;
  }

  return screen;
};

// Get an engagement by its ID and other informations
const getEngagementById = async (engagementId, currentUser) => {
  try {
    // Récupérer l'engagement par son ID
    const engagementRef = doc(db, "engagements", engagementId);
    const engagementSnap = await getDoc(engagementRef);

    if (!engagementSnap.exists()) {
      console.log("No such engagement!");
      return { success: false, errorType: "ENGAGEMENT_NOT_FOUND" };
    }

    // Vérifier si l'utilisateur a le droit d'accéder à l'engagement
    if (
      currentUser.organisation_id &&
      !engagementSnap.data().authorized_users_ids.includes(currentUser.uid)
    ) {
      console.log("Engagement does not belong to the user's organisation!");
      return { success: false, errorType: "ORG_MISMATCH" };
    }

    let engagement = { id: engagementSnap.id, ...engagementSnap.data() };

    // Retrieve client information
    const clientRef = doc(db, "clients", engagement.client_id);
    const clientSnap = await getDoc(clientRef);
    if (clientSnap.exists()) {
      engagement.client = { id: clientSnap.id, ...clientSnap.data() };
    }

    // Retrieve creator information
    const creatorRef = doc(db, "users", engagement.creator_id);
    const creatorSnap = await getDoc(creatorRef);
    if (creatorSnap.exists()) {
      engagement.creator = { id: creatorSnap.id, ...creatorSnap.data() };
    }

    // Récupérer les informations des écrans principaux et de leurs enfants
    if (engagement.screens && engagement.screens.length > 0) {
      let screensInfo = [];

      for (const screenId of engagement.screens) {
        const screen = await getScreenWithChildren(screenId);
        if (screen) {
          // Ajoute l'id du screen
          screen.uid = screenId;
          screensInfo.push(screen);
        }
      }

      engagement.screens = screensInfo;
    }

    return { success: true, engagement: engagement };
  } catch (error) {
    console.error("Error getting engagement by ID: ", error);
    throw error;
  }
};

// Get engagement by id
const getEngagementByIdLight = async (engagementId) => {
  try {
    const engagementRef = doc(db, "engagements", engagementId);
    const engagementSnap = await getDoc(engagementRef);

    if (!engagementSnap.exists()) {
      console.log("No such engagement!");
      return null;
    }

    let engagement = { id: engagementSnap.id, ...engagementSnap.data() };

    // Retrieve client information
    const clientRef = doc(db, "clients", engagement.client_id);
    const clientSnap = await getDoc(clientRef);
    if (clientSnap.exists()) {
      engagement.client = { id: clientSnap.id, ...clientSnap.data() };
    }

    return engagement;
  } catch (error) {
    console.error("Error getting engagement by ID: ", error);
    throw error;
  }
};

// Get inputs from ids
const getInputsByIds = async (inputIds) => {
  try {
    let inputs = [];

    for (const inputId of inputIds) {
      const inputRef = doc(db, "inputs", inputId);
      const inputSnap = await getDoc(inputRef);

      if (inputSnap.exists()) {
        inputs.push({
          id: inputSnap.id,
          ...inputSnap.data(),
          uid: inputId,
        });
      }
    }

    return inputs;
  } catch (error) {
    console.error("Error getting inputs by IDs: ", error);
    throw error;
  }
};

// Return a liste of screens with inputs data from a list of screens
const getScreensWithInputs = async (screens) => {
  try {
    let screensWithInputs = [];

    for (const screen of screens) {
      const screenRef = doc(db, "screens", screen);
      const screenSnap = await getDoc(screenRef);

      if (screenSnap.exists()) {
        let screenData = { id: screenSnap.id, ...screenSnap.data() };

        // Get inputs from screen
        const inputs = await getInputsByIds(screenData.inputs);
        screenData.inputs = inputs;

        screensWithInputs.push(screenData);
      }
    }

    return screensWithInputs;
  } catch (error) {
    console.error("Error getting screens with inputs: ", error);
    throw error;
  }
};

const updateInputValueById = async (inputId, value) => {
  try {
    const inputRef = doc(db, "inputs", inputId);
    await updateDoc(inputRef, {
      value: value,
    });

    console.log("Input updated with ID: ", inputId);
  } catch (e) {
    console.error("Error updating input: ", e);
  }
};

// Create application and add the id in the engagement application list
const addApplicationToEngagement = async (engagementId, application) => {
  try {
    const applicationRef = await addDoc(
      collection(db, "applications"),
      application,
    );

    const engagementRef = await doc(db, "engagements", engagementId);
    // Get screens from engagement
    const engagementSnap = await getDoc(engagementRef);
    // Get type id from engagement
    const templateName = "template" + engagementSnap.data().type_id;
    const { screens, appScreens } = await import(
      `../templates/${templateName}`
    );

    // Add application screens to the engagement
    await addScreenToEngagement(
      engagementId,
      appScreens(application.name + " - APP", applicationRef.id),
    );

    let engagement = { id: engagementSnap.id, ...engagementSnap.data() };

    // Get the last scrren id from engagement screens list
    const lastScreenId = engagement.screens[engagement.screens.length - 1];

    // Add the last screen id to the application
    await updateDoc(applicationRef, {
      screen_id: lastScreenId,
    });

    // Add the application id to the engagement applications list
    await updateDoc(engagementRef, {
      applications: arrayUnion(applicationRef.id),
    });

    console.log("Application added to engagement with ID: ", engagementId);
  } catch (e) {
    console.error("Error adding application to engagement: ", e);
  }
};

// Remove application id from engagement application list
const removeApplicationFromEngagement = async (
  engagementId,
  applicationId,
  screen_id,
) => {
  try {
    const engagementRef = doc(db, "engagements", engagementId);
    await updateDoc(engagementRef, {
      applications: arrayRemove(applicationId),
    });

    console.log("Application removed from engagement with ID: ", engagementId);

    // Delete cascade screens by screen id
    await deleteCascadeScreens(screen_id);
  } catch (e) {
    console.error("Error removing application from engagement: ", e);
  }
};

// Get applications ids of an engamgement
const getApplicationsIds = async (engagementId) => {
  try {
    const engagementRef = doc(db, "engagements", engagementId);
    const engagementSnap = await getDoc(engagementRef);

    if (!engagementSnap.exists()) {
      console.log("No such engagement!");
      return null;
    }

    return engagementSnap.data().applications;
  } catch (error) {
    console.error("Error getting applications IDs: ", error);
    throw error;
  }
};

// Get application by id
const getApplicationById = async (applicationId) => {
  try {
    const applicationRef = doc(db, "applications", applicationId);
    const applicationSnap = await getDoc(applicationRef);

    if (!applicationSnap.exists()) {
      console.log("No such application!");
      return null;
    }

    return { id: applicationSnap.id, ...applicationSnap.data() };
  } catch (error) {
    console.error("Error getting application by ID: ", error);
    throw error;
  }
};

// Get applications by a list of ids
const getApplicationsByIds = async (applicationIds) => {
  try {
    let applications = [];

    for (const application of applicationIds) {
      const applicationRef = doc(db, "applications", application);
      const applicationSnap = await getDoc(applicationRef);

      if (applicationSnap.exists()) {
        applications.push({
          id: applicationSnap.id,
          ...applicationSnap.data(),
        });
      }
    }

    return applications;
  } catch (error) {
    console.error("Error getting applications by IDs: ", error);
    throw error;
  }
};

// Update application by id
const updateApplicationById = async (applicationId, data) => {
  try {
    const applicationRef = doc(db, "applications", applicationId);
    await updateDoc(applicationRef, data);
    console.log("Application updated with ID: ", applicationId);
  } catch (e) {
    console.error("Error updating application: ", e);
  }
};

// Add file from input to storage
export const uploadFileToFirebase = async (file, path, input_uid) => {
  const storageRef = ref(storage, `${path}/${file.name}`);
  try {
    const snapshot = await uploadBytes(storageRef, file);
    // Get url of the uploaded file
    const url = await getDownloadURL(snapshot.ref);
    // Update input field nammed files_list add object with file name and timestamp
    const inputRef = doc(db, "inputs", input_uid);
    await updateDoc(inputRef, {
      files_list: arrayUnion({
        name: file.name,
        added_date: new Date(),
        url: url,
      }),
    });
    return snapshot;
  } catch (error) {
    throw new Error("Error uploading file: " + error.message);
  }
};

export const downloadFileFromStorage = async (storageUrl) => {
  try {
    // Exemple d'utilisation de Firebase Storage pour télécharger un fichier
    const storageRef = ref(storage, storageUrl);
    const file = await getDownloadURL(storageRef);
    return file;
  } catch (error) {
    console.error("Error downloading file:", error);
    throw error;
  }
};

// Update input by id
export const updateInputById = async (inputId, data) => {
  try {
    const inputRef = doc(db, "inputs", inputId);
    await updateDoc(inputRef, data);
    console.log("Input updated with ID: ", inputId);
  } catch (e) {
    console.error("Error updating input: ", e);
  }
};

// Update files_list by input id and file position in list
export const updateFilesListByInputId = async (inputId, data, index) => {
  try {
    const inputRef = doc(db, "inputs", inputId);
    const inputSnap = await getDoc(inputRef);
    const input = { id: inputSnap.id, ...inputSnap.data() };
    const filesList = input.files_list;
    data.added_date != ""
      ? (data.added_date = new Date(data.added_date))
      : (data.added_date = "");
    filesList[index] = data;
    await updateDoc(inputRef, {
      files_list: filesList,
    });
    console.log("Files list updated with ID: ", inputId);
  } catch (e) {
    console.error("Error updating files list: ", e);
  }
};

// Add files from a list of files selected to files list by input id
export const addFilesToFilesListByInputId = async (inputId, data) => {
  try {
    const inputRef = doc(db, "inputs", inputId);
    const inputSnap = await getDoc(inputRef);
    const input = { id: inputSnap.id, ...inputSnap.data() };
    let filesList = [];
    input.files_list ? (filesList = input.files_list) : (filesList = []);
    data.map((file) => {
      filesList.push(file);
    });
    await updateDoc(inputRef, {
      files_list: filesList,
    });
    console.log("Files added to files list with ID: ", inputId);
  } catch (e) {
    console.error("Error adding files to files list: ", e);
  }
};

// Delete file from input list
export const deleteFileFromInput = async (inputId, index) => {
  try {
    const inputRef = doc(db, "inputs", inputId);
    const inputSnap = await getDoc(inputRef);
    const input = { id: inputSnap.id, ...inputSnap.data() };
    const filesList = input.files_list;
    filesList.splice(index, 1);
    await updateDoc(inputRef, {
      files_list: filesList,
    });
    console.log("File deleted from input with ID: ", inputId);
  } catch (e) {
    console.error("Error deleting file from input: ", e);
  }
};

// Get all filenames of files from engagement
export const getFilesByEngagementId = async (engagementId) => {
  const folderRef = ref(storage, "Engagements/" + engagementId);
  try {
    const result = await listAll(folderRef);

    const filesPromises = result.items.map(async (itemRef) => {
      const url = await getDownloadURL(itemRef);
      return { name: itemRef.name, url };
    });

    const files = await Promise.all(filesPromises);

    return files;
  } catch (error) {
    console.error("Error getting files names: ", error);
  }
};

export const getFollowUpByEngagementId = async (engagementId) => {
  // Liste des inputs à suivre
  const inputsToFollowUp = [
    "APD1 Contrôle effectif",
    "APD1 niveau de criticité",
  ];

  // Récupérer les IDs des applications liées à l'engagement
  const applicationsIds = await getApplicationsIds(engagementId);

  // Fonction récursive pour vérifier si l'écran et ses enfants contiennent des inputs à suivre
  const checkFollowUp = async (screen) => {
    let screensList = [];
    if (screen.inputs && screen.inputs.length > 0) {
      // Récupérer les inputs associés à l'écran
      const inputs = await getInputsByIds(screen.inputs);
      const inputsToKeep = inputs.filter((input) => input.suivi === true);

      // Si on trouve des inputs à suivre, on garde cet écran
      if (inputsToKeep.length > 0) {
        screensList.push({ ...screen, inputs: inputsToKeep });
      }
    }

    // Vérifier les enfants de l'écran
    if (screen.children && screen.children.length > 0) {
      for (const child of screen.children) {
        const childScreens = await checkFollowUp(child);
        screensList = screensList.concat(childScreens);
      }
    }
    return screensList;
  };

  // Récupérer les données des applications et des écrans en parallèle
  const followUpInputs = await Promise.all(
    applicationsIds.map(async (applicationId) => {
      const application = await getApplicationById(applicationId);
      const screens = await getScreenWithChildren(application.screen_id);

      // Vérifier les écrans de cette application
      const screensList = await checkFollowUp(screens);

      // Retourner les données de suivi pour cette application
      return {
        applicationData: application,
        screens: screensList,
      };
    }),
  );

  return followUpInputs;
};

// Get favorite engagments informations by user Id
export const getFavoritesByUserId = async (userId) => {
  try {
    const userRef = doc(db, "users", userId);
    const userSnap = await getDoc(userRef);

    if (!userSnap.exists()) {
      console.log("No such user!");
      return null;
    }

    const user = { id: userSnap.id, ...userSnap.data() };
    const favorites = user.favoriteEngagements || [];

    let favoritesList = [];
    for (const favorite of favorites) {
      const engagement = await getEngagementByIdLight(favorite);
      favoritesList.push(engagement);
    }

    return favoritesList;
  } catch (error) {
    console.error("Error getting favorites by user ID: ", error);
    throw error;
  }
};

export {
  createEngagement,
  getEngagements,
  getEngagementById,
  getInputsByIds,
  updateInputValueById,
  addApplicationToEngagement,
  removeApplicationFromEngagement,
  getApplicationById,
  getApplicationsByIds,
  getApplicationsIds,
  updateApplicationById,
  setScreenLock,
  updateEngagement,
  getScreensWithInputs,
};
